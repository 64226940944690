import PageCommon from "./PageCommon.js";
import Pqp from "../3x1010/Pqp.js";
import Globals from "../Globals.js";

export default class PageDefault extends PageCommon {
  constructor() {
    super();
  }
  init($data) {  

    super.init($data);
    this.showPreloader(false)
    Pqp.trace("PageDefault - Hello world");
    // dispaccio un evento globale
    try {
      this.dispatchInitEvent(TheDisp.EV_PAGETHIS_INITED);
    } catch (error) {
      // console.log(error);
    }
    let Nft = $data.Nft;
    let Events = $data.Event;
    let Faq = $data.Faq;
    this.createNftList(Nft);
    this.createEvent(Events);
    this.createFaq(Faq);
    $(".single-faq").on("click", this.toggleFaq.bind(this));
    $(".hamburger").on("click", this.mobileMenu.bind(this));
    $('.js-toggle-show-dona').on("click", ()=>{
      $(".dona-show").toggleClass("show");
      $(".js-toggle-show-dona").toggleClass("open")
    });
  }

  get isHomepage() {
    return true;
  }
  mobileMenu() {
    $(".panel-menu").addClass("open");
    $(".single-mobile-voice").on("click", () => {
      $(".panel-menu").removeClass("open");
    })
    $(".close").on("click", () => {
      $(".panel-menu").removeClass("open");
    })
  }
  toggleFaq(e) {
    let target = e.target;
    target.parentElement.classList.toggle('open')
  }

  createFaq(faqs) {
    for (let i = 0; i < faqs.length; i++) {
      let faq = $("#template-faq").clone();
      faq.attr("id", `faq-${faqs[i].Uid}`);
      $(".question", faq).html(faqs[i].question);
      $(".answer", faq).html(faqs[i].answer);
      $(".wrapper-faq").append(faq);
    }
  }


  createNftList(nft) {
    for (let i = 0; i < nft.length; i++) {
      let el = $("#template-nft").clone();
      el.attr("id", `nft-${nft[i].Uid}`);

      $(".js-nft-image", el).attr("src", nft[i].ImageUrl);
      $(".js-nft-image", el).attr("alt", nft[i].Title);
      $(".js-card-link", el).attr("href", nft[i].Url);
      $(".js-nft-title", el).html(nft[i].Title);
      if (!Pqp.isNullOrEmpty(nft[i].Price)) {
        $(".js-nft-price", el).html(nft[i].Price).show();
      }
      $(".js-nft-link", el).html(nft[i].txtCallToAction);
      $(".js-nft-link", el).attr("href", nft[i].Url);
      $(".js-wrapper-nft").append(el);
    }
  }
  createEvent(list) {
    if (list.length === 0) {
      $(".event").hide();

    }
    for (let i = 0; i < list.length; i++) {
      let evento = $("#template-event").clone();
      evento.attr("id", `evento-${list[i].Uid}`);
      $(".number", evento).html(list[i].DateNumber);
      $(".month-year", evento).html(list[i].MonthYear);
      $(".day-time", evento).html(list[i].DayTime);
      $(".title", evento).html(list[i].Title);
      $(".description", evento).html(list[i].Description);
      $(".wrapper-list-event").append(evento)
      $(".link-evento", evento).html("Coming soon");
      $(".js-event").show();
      if (!list[i].isCoomingSoon) {
        $(".link-evento", evento).html("More details");
        $(".link-evento", evento).attr("href", list[i].url);
      }
    }
  }
}
