import "../scss/styles.scss";
import PageCommon from "./pages/PageCommon.js";
import PageDefault from "./pages/PageDefault.js";

export default class FactoryManager {
  static factory(cls) {
    return new FactoryManager.classes[cls]();
  }
}

FactoryManager.classes = { PageCommon, PageDefault };
